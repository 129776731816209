import React, { useContext, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { Link, navigate } from "gatsby";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing";
import { useForm } from "react-hook-form";

import { AuthContext } from "../context/auth";
import api from "../services/api";

import logo from "../../static/images/logo-novo.png";
import Header from "../components/header";
import Newsletter from "../components/newsletter";
import Footer from "../components/footer";

const GlobalStyle = createGlobalStyle`
  .ReactModal__Overlay {
    z-index: 11;
    background-color: #fff!important;
    display: flex;
    align-items: center;
  }
  
  .ReactModal__Content {
    width: 100%;
    left: 0!important;
    right: 0!important;
    top: 0!important;
    bottom: 0!important;
    border: 0!important;
    border-bottom: 15px solid #f1a857!important;
    border-radius: 0!important;
  }

  .modal-content.modal-out {
    width: 100%;
    padding: 0px 20px 0;
    margin: 0 auto;
    background: #fff;
  }
  .modal-background {
    background: #fff;
    border-bottom: 15px solid #f1a857!important;
  }

  .modal-content {
    text-align: center;

    .close-modal {
      position: absolute;
      right: 20px;
      top: 7px;

      border: 1px solid #a57d73;
      height: 38px;
      padding: 5px 10px;
      border-radius: 3px;

      display: flex;
      align-items: center;

      font: bold italic 18px 'Times new roman';
      color: #a57d73;

      i {
        background: url("/images/close-modal.svg") no-repeat center;
        background-size: contain;
        width: 17px;
        height: 17px;
        margin-left: 7px;
      }
    }

    .logo-image {
      max-height: 140px;
      width: auto;
      margin-bottom: 40px;
    }
    
    form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      input {
        border: 0;
        border-bottom: 2px solid #b1b1b1;
        padding: 11px 0;
        margin-bottom: 30px;
        color: #000000;
        font: bold italic 20px 'Times new roman';

        &::placeholder {
          color: #000000;
        }

        &.input-error {
          border-bottom: 2px solid #bd0303;
          color: #bd0303;

          &::placeholder {
            color: #bd0303;
          }
        }
      }

      a {
        font-size: 14px;
        color: #848484;
        letter-spacing: 0.32px;
        display: block;
        text-align: left;
      }

      button {
        width: 210px;
        height: 60px;
        background: #f1a857;
        border-radius: 3px;
        font-weight: 500;
        
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0.32px;
        cursor: pointer;
        margin: 60px auto 0;

        transition: .2s all ease;

        &:hover {
          background: #e59134;
        }
      }
    }

    .error-login {
      color: #bd0303;
      display: block;
      margin-top: 30px;
    }
  }

  .grey {
    background: #DEDEDE;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;

    p {
      text-align: center;
      font: italic bold 30px 'Times new roman';
      color: #000000;
      margin-bottom: 25px;
    }

    a {
      width: 240px;
      height: 60px;
      background: #f1a857;
      border-radius: 3px;
      font-weight: 500;
      
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 16px;
      color: #ffffff;
      letter-spacing: 0.32px;
      cursor: pointer;
      margin: 0 auto;

      transition: .2s all ease;

      &:hover {
        background: #e59134;
      }
    }
  }

  @media (min-width: 767px) {
    .ReactModal__Overlay {
      background-color: rgba(0, 0, 0, 0.6)!important;
    }

    .ReactModal__Content {
      max-width: 577px;
      margin: 0 auto;
      height: auto;
      border-radius: 3px!important;
      top: unset!important;
      bottom: unset!important;
      padding: 65px 0 0px!important;
    }

    form {
      padding: 0 55px;
    }

    .modal-content.modal-out {
      max-width: 577px;
      border-radius: 3px!important;
      padding: 65px 55px 70px!important;
      border-bottom: 15px solid #f1a857!important;
    }
    .modal-background {
      background: transparent;
      border-bottom: unset!important;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .modal-content {
      .close-modal {
        display: none;
      }
    }
  }
`;

const Login = () => {
  const { signIn } = useContext(AuthContext);
  const { register, handleSubmit, errors } = useForm();
  const [errorMessage, setErrorMessage] = useState("");

  async function handleSendForm(data) {
    try {
      const formData = {
        email: data.email,
        pwd: data.password,
      };

      const responseLogin = await api.post(
        "https://apimeuambiente.guararapes.com.br/user/login/",
        formData
      );

      if (responseLogin.data.status === false) {
        setErrorMessage(
          "Sua conta ainda não está ativada, será ativada em breve."
        );
      } else {
        signIn(responseLogin.data.user);

        navigate("/meu-perfil");
      }
    } catch (error) {
      setErrorMessage("Email ou senha incorretos, por favor tente novamente.");
    }
  }

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <>
          {!modal && <Header />}
          <div className={!modal ? "modal-background" : ""}>
            <div
              className={!modal ? "modal-content modal-out" : "modal-content"}
            >
              {modal && (
                <Link to={closeTo} className="close-modal">
                  Fechar
                  <i></i>
                </Link>
              )}

              <img src={logo} alt="Logo" className="logo-image" />

              <form onSubmit={handleSubmit(handleSendForm)}>
                <input
                  type="text"
                  placeholder="Seu email *"
                  name="email"
                  className={errors.email && "input-error"}
                  ref={register({ required: true })}
                />
                <input
                  type="password"
                  placeholder="Sua senha *"
                  name="password"
                  className={errors.password && "input-error"}
                  ref={register({ required: true })}
                />

                <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>

                <button type="submit">Fazer Login</button>
              </form>

              {errorMessage && (
                <span className="error-login">{errorMessage}</span>
              )}

              <span className="grey">
                <p>Ainda não tem cadastro?</p>

                <Link to="/registrar">Criar meu cadastro</Link>
              </span>
              <GlobalStyle />
            </div>
          </div>

          {!modal && (
            <>
              <Newsletter />
              <Footer />
            </>
          )}
        </>
      )}
    </ModalRoutingContext.Consumer>
  );
};

export default Login;
